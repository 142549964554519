var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "verify-address-modal",
        title: "Verify Address",
        size: "xl",
        "hide-footer": "",
      },
      on: { hidden: _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [_vm._v("\n    Select Your Mail Preferences\n  ")]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("p", [
        _vm._v(
          "Confirm the address where you'd like us to mail your plates and registration once we receive them from the state."
        ),
      ]),
      _c(
        "b-form-radio-group",
        {
          model: {
            value: _vm.addressVerificationNeeded,
            callback: function ($$v) {
              _vm.addressVerificationNeeded = $$v
            },
            expression: "addressVerificationNeeded",
          },
        },
        [
          _c("b-form-radio", { staticClass: "mb-1", attrs: { value: false } }, [
            _vm._v(
              "Please mail my plates and registration to the address below."
            ),
          ]),
          _c("b-form-radio", { staticClass: "mb-3", attrs: { value: true } }, [
            _vm._v(
              "I will be traveling, so please contact me for an address before mailing my plates and registration."
            ),
          ]),
        ],
        1
      ),
      _c("validation-observer", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                _vm.addressVerificationNeeded
                  ? _c("div", [
                      _c("h5", { staticClass: "my-3" }, [
                        _vm._v("We'll Contact You for Your Address"),
                      ]),
                      _c("p", [
                        _vm._v(
                          "We will reach out to you for your address once your plates and registration are ready for mailing."
                        ),
                      ]),
                    ])
                  : _c(
                      "div",
                      [
                        _c("h5", { staticClass: "my-3" }, [
                          _vm._v("Mail My Plates And Registration To:"),
                        ]),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Address" } },
                          [
                            _c("validation-provider", {
                              attrs: {
                                rules: "required|notKalispell",
                                name: "Address",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                      _c("b-form-select", {
                                        attrs: {
                                          options: _vm.addressOptions,
                                          state: !errors[0],
                                        },
                                        model: {
                                          value: _vm.selectedAddress,
                                          callback: function ($$v) {
                                            _vm.selectedAddress = $$v
                                          },
                                          expression: "selectedAddress",
                                        },
                                      }),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "manage-addresses",
                                          attrs: { variant: "link" },
                                          on: {
                                            click: _vm.openAddressSettings,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n            Manage Addresses\n          "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                _c(
                  "div",
                  { staticClass: "footer" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "secondary", disabled: _vm.saving },
                        on: {
                          click: function ($event) {
                            return _vm.$bvModal.hide("verify-address-modal")
                          },
                        },
                      },
                      [_vm._v("\n        Cancel\n      ")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "save-button",
                        attrs: {
                          variant: "primary",
                          disabled: _vm.saving || invalid,
                        },
                        on: { click: _vm.save },
                      },
                      [
                        _vm.saving
                          ? _c("fa-icon", {
                              attrs: { icon: "spinner", size: "lg", spin: "" },
                            })
                          : _c("span", [_vm._v("Save")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("address-settings-modal", { attrs: { bus: _vm.bus } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }