<template>
  <b-modal
    id="verify-address-modal"
    v-model="showModal"
    title="Verify Address"
    size="xl"
    hide-footer
    @hidden="closeModal"
  >
    <template #modal-header>
      Select Your Mail Preferences
    </template>

    <p>Confirm the address where you'd like us to mail your plates and registration once we receive them from the state.</p>

    <b-form-radio-group v-model="addressVerificationNeeded">
      <b-form-radio :value="false" class="mb-1">Please mail my plates and registration to the address below.</b-form-radio>
      <b-form-radio :value="true" class="mb-3">I will be traveling, so please contact me for an address before mailing my plates and registration.</b-form-radio>
    </b-form-radio-group>

    <validation-observer v-slot="{ invalid }">
      <div v-if="addressVerificationNeeded">
        <h5 class="my-3">We'll Contact You for Your Address</h5>
        <p>We will reach out to you for your address once your plates and registration are ready for mailing.</p>
      </div>
      <div v-else>
        <h5 class="my-3">Mail My Plates And Registration To:</h5>
        <b-form-group label="Address">
          <validation-provider
            v-slot="{ errors }"
            rules="required|notKalispell"
            name="Address"
          >
            <div class="text-danger">{{ errors[0] }}</div>
            <b-form-select
              v-model="selectedAddress"
              :options="addressOptions"
              :state="!errors[0]"
            />
            <b-button
              variant="link"
              class="manage-addresses"
              @click="openAddressSettings"
            >
              Manage Addresses
            </b-button>
          </validation-provider>
        </b-form-group>
      </div>

      <div class="footer">
        <b-button
          variant="secondary"
          :disabled="saving"
          @click="$bvModal.hide('verify-address-modal')"
        >
          Cancel
        </b-button>
        <b-button
          class="save-button"
          variant="primary"
          :disabled="saving || invalid"
          @click="save"
        >
          <fa-icon v-if="saving" icon="spinner" size="lg" spin />
          <span v-else>Save</span>
        </b-button>
      </div>
    </validation-observer>

    <address-settings-modal :bus="bus" />
  </b-modal>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { addressFormatter } from '@/mixins/addressFormatter'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import AddressSettingsModal from '@/components/AccountSettings/AddressSettingsModal'

export default {
  name: 'VerifyAddressModal',
  components: {
    ValidationObserver,
    ValidationProvider,
    AddressSettingsModal,
  },
  mixins: [addressFormatter, makeToastMixin],
  props: {
    orderItem: {
      type: Object,
    },
  },
  data() {
    return {
      bus: new Vue(),
      showModal: false,
      saving: false,
      orderItemId: this.$route.params.orderItemId,
      addressVerificationNeeded: true,
      selectedAddress: null,
    }
  },
  computed: {
    ...mapGetters('account', ['addresses']),
    addressOptions() {
      return this.addresses.map((address) => {
        const formatted = this.formattedAddress(address)
        return { value: formatted, text: formatted }
      })
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.showModal = to.name === 'verifyAddressModal'
        if (this.showModal) {
          this.load()
        }
      },
    },
  },
  methods: {
    ...mapActions('orders', ['updateItemData']),
    ...mapActions('orderItems', ['setOrderItemStatus']),
    load () {
      this.addressVerificationNeeded = this.orderItem.data?.address_verification_needed || false
      if(this.orderItem.data?.mail_to_address && this.addressOptions.includes(this.orderItem.data?.mail_to_address)) {
        this.selectedAddress = this.orderItem.data.mail_to_address
      } else {
        this.selectedAddress = this.formattedAddress(this.preferredAddress(['mailing', 'primary'], this.addresses))
      }
    },
    openAddressSettings () {
      this.bus.$emit('address-settings-modal')
    },
    async save () {
      try {
        this.saving = true
        this.orderItem.data.address_verification_needed = this.addressVerificationNeeded
        if (!this.addressVerificationNeeded) {
          this.orderItem.data.mail_to_address = this.selectedAddress
        }
        const itemData = {}
        itemData[this.orderItem.id] = this.orderItem.data
        await this.updateItemData(itemData)
        if (this.orderItem.status === 'waiting-on-address') {
          this.orderItem.status = 'ready-to-mail'
          await this.setOrderItemStatus({ id: this.orderItem.id, status: this.orderItem.status })
        }
        this.successToast('Saved!', 'Your preference has been saved.')
        this.$bvModal.hide('verify-address-modal')
      } catch(error) {
        this.errorToast('Error', error?.message || 'Unable to save')
      }
      this.saving = false
    },
    closeModal () {
      this.$emit('closeVehicleOrderModal')
    },
  },
}
</script>

<style lang="scss" scoped>
.manage-addresses {
  font-size: 0.875rem;
  padding: .5rem .25rem;
}
.footer {
  display: flex;
  justify-content: end;
  padding: 1rem 0;
  margin-top: 2rem;
  border-top: 1px solid $border-color;

  button {
    margin: 0 0.25rem;
  }
}
.save-button {
  min-width: 7rem;
}
</style>
